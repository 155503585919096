import React, { ReactNode, useState } from 'react';
import { Page as TemplatePage, ContentArea, Main, Body, Header } from '@cvent/carina/components/Templates';
import { Footer } from '@components/Footer';
import { Theme, useBuildStyleWithTheme } from '@cvent/carina/components/ThemeProvider';
import { CSSObject } from '@emotion/react';
import { TopNavigation } from '@components/header/TopNavigation';
import { useCookies } from 'react-cookie';
import { COOKIE_NAME_GDPR_ACCEPTED_TIME } from '@utils/constants';
import { GdprCookieConsent } from './GdprCookieConsent';

/**
 * Builds a CSS style object for the content area.
 *
 * @param {{backgroundColor}: Theme} - The theme object with the background color information.
 * @returns {CSSObject} The CSS style object.
 */
const contentAreaBuildStyle = ({ backgroundColor }: Theme): CSSObject => ({
  flexGrow: 1,
  overflowY: 'auto',
  backgroundColor: backgroundColor.surface
});

export function BasePage({ children }: { children: ReactNode }): JSX.Element {
  const [cookies, setCookies] = useCookies();
  const [gdprAccepted, setGdprAccepted] = useState(!!cookies[COOKIE_NAME_GDPR_ACCEPTED_TIME]);

  // function to be called when the user click the accept button
  const _onGdprAccept = () => {
    // store the unix timestamp of the moment gdpr was accepted, and expire the cookie after 1 year.
    const dateNow: Date = new Date(Date.now());
    const cookieExpiry: Date = new Date(dateNow.getFullYear() + 1, dateNow.getMonth(), dateNow.getDate());

    setCookies(COOKIE_NAME_GDPR_ACCEPTED_TIME, Date.now(), { expires: cookieExpiry });
    setGdprAccepted(true);
  };

  return (
    <TemplatePage>
      <Main as="div">
        <GdprCookieConsent alreadyAccepted={gdprAccepted} onAccept={_onGdprAccept} />
        <Header testID="header">
          <TopNavigation />
        </Header>
        <Body>
          <ContentArea>
            <div css={useBuildStyleWithTheme(contentAreaBuildStyle)}>
              {children}
              <Footer />
            </div>
          </ContentArea>
        </Body>
      </Main>
    </TemplatePage>
  );
}
