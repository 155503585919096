/* eslint-disable @typescript-eslint/no-empty-function */
// TODO: move this css boilerplate into a next.js plugin
import 'normalize.css'; // css reset
import '@cvent/carina/fonts/fonts.web.css';
// eslint-disable-next-line import/order
import { datadogOptions } from '../config/initBrowserLogging';
import React, { useMemo } from 'react';
import { ApolloProvider, InMemoryCache, useQuery } from '@apollo/client';
import { useApollo } from '@cvent/apollo-client/useApollo';
import { ApolloClientFactory } from '@cvent/apollo-client/ApolloClientFactory';
import { onError } from '@apollo/client/link/error';
import AppProviders from '@components/providers/AppProviders';
import NavigationPage from '@components/NavigationPage';
import ErrorBoundary from '@components/ErrorBoundary';
import { useRouter } from 'next/router';
import { LoggerFactory } from '@cvent/logging/LoggerFactory';
import { GET_APP_NAVIGATION_QUERY } from '@cvent/planner-navigation/client/queries';
import getConfig from 'next/config';
import { initRum, Link } from '@cvent/nextjs';
import { Invitation } from '@cvent/developer-portal-graphql/types';
import { v4 as uuid } from 'uuid';
import {
  applicationFormDirty,
  darkModeVar,
  clientVersion,
  localeVar,
  activeInvitationVar,
  activeAccountMappingId
} from 'src/config/reactiveVars';
import { useDocSiteNavigation } from '@hooks/docSiteNavigation';
import { TopNavigation } from '@components/header/TopNavigation';
import { Layout } from '@cvent/docs';
import Footer from '@components/Footer';
import { BasePage } from '../components/BasePage';

const { publicRuntimeConfig } = getConfig();
const LOG = LoggerFactory.create('App');
LOG.info('New application started');

const DOCSITE_NAV_METADATA = {
  url: '/docs',
  staticRouteId: '/docs',
  topParentId: ''
};

export default function App({ Component, pageProps }): JSX.Element {
  initRum(datadogOptions);

  // if the graph ql operations fail for any reason, log them here and redirect to /500 page
  const apolloErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const instanceId = uuid();
    const date = new Date().toUTCString();
    const operationErrorMessage = `error performing operation: ${operation.operationName}`;
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message }) =>
        LOG.error(instanceId, operationErrorMessage, `[graphQL error]: ${message}`)
      );
    }
    if (networkError) {
      LOG.error(instanceId, operationErrorMessage, `[network error]: ${networkError}`);
    }
    router.push(`/500?errorId=${instanceId}&date=${encodeURIComponent(date)}`);
  });
  const apolloFactory = new ApolloClientFactory({
    initialState: {},
    loginUrl: publicRuntimeConfig.LOGIN_URL,
    graphBasePath: '',
    graphUri: '/api/graphql',
    errorLink: apolloErrorLink
  });
  const client = useApollo(pageProps, apolloFactory, {
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            clientVersion: {
              read(): string {
                return clientVersion();
              }
            },
            darkMode: {
              read(): boolean {
                return darkModeVar();
              }
            },
            locale: {
              read(): string {
                return localeVar();
              }
            },
            activeInvitation: {
              read(): Invitation {
                return activeInvitationVar();
              }
            },
            applicationFormDirty: {
              read(): boolean {
                return applicationFormDirty();
              }
            },
            activeAccountMappingId: {
              read(): string {
                return activeAccountMappingId();
              }
            }
          }
        }
      }
    })
    // set any global apollo overrides here for browser side apollo
  });

  const router = useRouter();
  const docsiteNav = useDocSiteNavigation();

  const navMetadata = useMemo(
    () => pageProps?.navMetadata || Component.navMetadata || (docsiteNav ? DOCSITE_NAV_METADATA : undefined),
    [pageProps?.navMetadata, Component.navMetadata, docsiteNav]
  );

  const { loading, data } = useQuery(GET_APP_NAVIGATION_QUERY, {
    client,
    variables: {
      navMetadata
    },
    skip: !navMetadata
  });

  const component = useMemo(() => {
    if (loading) {
      // TODO: empty for now, discuss options with Carina team and UX.
      // implement getStaticProps or getServerSideProps on a page to avoid
      return <div />;
    }

    const handlers = {
      setSearchTerm: (term): void => {
        LOG.info('SEARCH TERM', term);
      },
      submitFilters: (content): void => {
        LOG.info('FILTERS CONTENT', content);
      },
      onSearch: (term?: string): void => {
        LOG.info('onSearch', term);
        router.push(`/search?term=${encodeURIComponent(term)}`);
      }
    };

    const nav = {
      ...data?.navigation,
      ...handlers,
      Link
    };

    return (
      <ApolloProvider client={client}>
        <AppProviders>
          <ErrorBoundary>
            {navMetadata ? (
              <NavigationPage nav={nav} pageTitle={data?.navigation.page}>
                {docsiteNav ? (
                  <Layout
                    headerComponent={<TopNavigation showTitleBar={false} />}
                    nav={docsiteNav}
                    hidePageHeader={[]}
                    footerComponent={<Footer />}
                  >
                    <Component {...pageProps} />
                  </Layout>
                ) : (
                  <BasePage>
                    <Component {...pageProps} />
                  </BasePage>
                )}
              </NavigationPage>
            ) : (
              <Component {...pageProps} />
            )}
          </ErrorBoundary>
        </AppProviders>
      </ApolloProvider>
    );
  }, [loading, data?.navigation, client, navMetadata, Component, pageProps, router, docsiteNav]);

  return component;
}
