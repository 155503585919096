import React from 'react';
import { CustomIcon } from '@cvent/carina/components/Icon';

const YoutubeIconSVG = (
  <g>
    <svg viewBox="0 0 32 23">
      <path
        d="M16.323 0c.868.004 3.355.023 5.905.116l.612.025c2.345.097 4.637.264 5.662.546 1.377.377 2.461 1.49 2.83 2.905.606 2.323.662 6.937.667 7.776l.001.123v.141c-.006.839-.062 5.452-.669 7.777-.368 1.413-1.452 2.527-2.829 2.905-1.025.281-3.317.448-5.662.546l-.612.024c-2.55.093-5.037.112-5.905.115h-.646a193.12 193.12 0 01-5.905-.115l-.612-.024c-2.345-.098-4.637-.265-5.662-.546-1.377-.378-2.461-1.492-2.83-2.905-.606-2.325-.662-6.938-.667-7.777v-.264c.005-.839.061-5.453.668-7.776C1.037 2.178 2.12 1.064 3.498.687 4.523.405 6.815.238 9.16.14l.612-.025c2.55-.093 5.037-.112 5.905-.115zm-3.596 6.646v9.708l8.364-4.854-8.364-4.854z"
        id="Icon"
      />
    </svg>
  </g>
);
function YoutubeIcon(): JSX.Element {
  return <CustomIcon>{YoutubeIconSVG}</CustomIcon>;
}
export default YoutubeIcon;
