import React from 'react';
import { Button } from '@cvent/carina/components/Button';
import { LogInIcon } from '@cvent/carina/components/Icon';
import { useTranslate } from 'nucleus-text';
import { deleteAccountCookies, deleteOktaSession } from '@utils';
import { Space } from '@components/Space';
import { useQuery } from '@apollo/client';
import { GetOktaSessionUrlDocument } from '@cvent/developer-portal-graphql/operations';
import { useRouter } from 'next/router';

/**
 * Login button for header for a logged out user
 *
 * @returns rendered Login Button
 */
export function LoginButton() {
  const { translate } = useTranslate();
  const router = useRouter();

  const _deleteSessionUrl = useQuery(GetOktaSessionUrlDocument).data?.getOktaSessionUrl;

  return (
    <>
      <Button
        testID="header.login.button"
        variant="neutral"
        icon={LogInIcon}
        text={translate('buttons.login')}
        onClick={async () => {
          await deleteOktaSession(_deleteSessionUrl);
          await deleteAccountCookies();
          router.push('/login');
        }}
        size="m"
      />
      <Space />
    </>
  );
}
