/* eslint-disable @typescript-eslint/no-empty-function */
// TODO: move this css boilerplate into a next.js plugin
import 'normalize.css'; // css reset
import '@cvent/carina/fonts/fonts.web.css';

import React from 'react';
import { LocalizationProvider } from 'nucleus-text';
import { useQuery } from '@apollo/client';
import { GetLocaleDocument } from '@cvent/developer-portal-graphql/operations';
import { CookiesProvider } from 'react-cookie';
import locales from '../../../locales';
import ThemeProvider from './ThemeProvider';

export default function AppProviders({ children }: { children }): JSX.Element {
  const { data } = useQuery(GetLocaleDocument);
  const { locale } = data;

  return (
    <CookiesProvider>
      <ThemeProvider>
        <LocalizationProvider locale={locale} clientSideLocales={locales} serverSideLocales={locales}>
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
}
