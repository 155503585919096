import React, { useMemo } from 'react';
import Image from 'next/image';
import { CSSObject } from '@emotion/react';
import { TextLink } from '@cvent/carina/components/TextLink';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { useTranslate } from 'nucleus-text';
import { LinkList, Item } from '@cvent/carina/components/LinkList';
import { useFooterStyle } from '@hooks/style/useFooterStyle';
import cventLogo from '@images/cvent-logo.png';
import YoutubeIcon from '@images/YoutubeIcon';
import { FacebookIcon, LinkedinIcon, TwitterIcon } from '@cvent/carina/components/Icon';
import useLoggedIn from '@hooks/loggedIn/useLoggedIn';

export interface Link {
  children?: unknown;
  href?: string;
  testID?: string;
  socialMediaLinks?: boolean;
}

export interface FooterSection {
  header: string;
  links: Array<Link>;
}

export interface FooterColumnProps {
  header: string;
  column: CSSObject;
  columnHeader: CSSObject;
  linkListStyle: CSSObject;
  columnLinkList: CSSObject;
  links: Array<Link>;
}

/**
 * A column of links in the footer
 */
function FooterColumn({
  header,
  column,
  columnHeader,
  linkListStyle,
  columnLinkList,
  links
}: FooterColumnProps): JSX.Element {
  const { translate } = useTranslate();
  return (
    <div css={column}>
      <h5 css={columnHeader}> {translate(header)} </h5>
      <LinkList condensedBlock css={linkListStyle}>
        {links.map((link: Link): JSX.Element => {
          if (link.socialMediaLinks) {
            return (
              <Item key="socialMediaLinks">
                <TextLink
                  href="https://www.facebook.com/cvent"
                  target="_blank"
                  rel="noopener noreferrer"
                  testID="footer.icons.facebook"
                  icon={<FacebookIcon />}
                />
                <TextLink
                  href="https://www.linkedin.com/companies/cvent"
                  target="_blank"
                  rel="noopener noreferrer"
                  testID="footer.icons.linkedin"
                  icon={<LinkedinIcon />}
                />
                <TextLink
                  href="https://twitter.com/cvent"
                  target="_blank"
                  rel="noopener noreferrer"
                  testID="footer.icons.twitter"
                  icon={<TwitterIcon />}
                />
                <TextLink
                  href="https://www.youtube.com/user/CventVideo"
                  target="_blank"
                  rel="noopener noreferrer"
                  testID="footer.icons.youtube"
                  icon={<YoutubeIcon />}
                />
              </Item>
            );
          }
          return <FooterColumnLink key={link.href} css={columnLinkList} {...link} />;
        })}
      </LinkList>
    </div>
  );
}

/**
 * Footer link in a list item for the footer column
 * @param {*} columnProps Props to Drop
 */
function FooterColumnLink(columnProps): JSX.Element {
  return (
    <Item>
      <TextLink {...columnProps} neutral target="_blank" rel="noopener noreferrer" />
    </Item>
  );
}

export function Footer(): JSX.Element {
  const year: number = useMemo(() => new Date().getFullYear(), []);
  const { translate } = useTranslate();

  const isLoggedIn = useLoggedIn();

  const {
    globalFooterStyle,
    publicFooterStyle,
    leftContainerStyle,
    rightContainerStyle,
    cventTextStyle,
    linkTextStyle,
    divider,
    columnHeader,
    columnLinkList,
    column,
    linkListStyle
  } = useFooterStyle();

  const termsOfUseText = translate('termsOfUse');
  const cventPrivacyPolicyText = translate('footer.privacyPolicy');
  const helpLinkText = translate('footer.helpAndSupport');

  const footerLinks: Array<FooterSection> = [
    {
      header: 'footer.discover',
      links: [
        {
          children: translate('footer.searchEventVenues'),
          href: 'https://www.cvent.com/venues/',
          testID: 'footer.links.search-event-venues'
        },
        {
          children: translate('footer.findVenuePromotions'),
          href: 'https://www.cvent.com/rfp/hotel-promotions/',
          testID: 'footer.links.find-venue-promotions'
        },
        {
          children: translate('footer.exploreCityGuides'),
          href: 'https://www.cvent.com/rfp/meeting-event-planning.aspx',
          testID: 'footer.links.explore-city-guides'
        },
        {
          children: translate('footer.newDiscoveryLink'),
          href: 'https://www.cvent.com/venues/discover',
          testID: 'footer.links.new-discovery-link'
        },
        {
          children: translate('footer.newLuxuryLink'),
          href: 'https://www.cvent.com/microsites/cvent-meetings-magazine',
          testID: 'footer.links.new-luxury-link'
        }
      ]
    },
    {
      header: 'footer.solutions',
      links: [
        {
          children: translate('footer.eventManagementSoftware'),
          href: 'https://www.cvent.com/en/event-management-software/',
          testID: 'footer.links.event-management-software'
        },
        {
          children: translate('footer.mobileEventApps'),
          href: 'https://www.cvent.com/en/mobile-event-apps/',
          testID: 'footer.links.mobile-event-apps'
        },
        {
          children: translate('footer.cventSupplierNetwork'),
          href: 'https://www.cvent.com/venues/',
          testID: 'footer.links.cvent-supplier-network'
        },
        {
          children: translate('footer.strategicMeetingsManagement'),
          href: 'https://www.cvent.com/en/strategic-meetings-management/',
          testID: 'footer.links.strategic-meetings-management'
        },
        {
          children: translate('footer.webSurveySoftware'),
          href: 'https://www.cvent.com/en/web-survey-software/',
          testID: 'footer.links.web-survey-software'
        }
      ]
    },
    {
      header: 'footer.company',
      links: [
        {
          children: translate('footer.cventHome'),
          href: 'https://www.cvent.com/',
          testID: 'footer.links.cvent-home'
        },
        {
          children: translate('footer.contactUs'),
          href: 'https://www.cvent.com/en/contact/',
          testID: 'footer.links.contact-us'
        },
        {
          children: translate('footer.advertiseWithUs'),
          href: 'https://www.cvent.com/en/supplier-network/group-business-suppliers.shtml',
          testID: 'footer.links.advertiseWithUs'
        },
        {
          socialMediaLinks: true
        }
      ]
    }
  ];

  return (
    <>
      {!isLoggedIn && (
        <footer css={publicFooterStyle} {...injectTestId('footer.publicFooter')}>
          {footerLinks.map((section: FooterSection): JSX.Element => {
            const footerColumnProps: FooterColumnProps = {
              header: section.header,
              column,
              columnHeader,
              linkListStyle,
              columnLinkList,
              links: section.links
            };
            return <FooterColumn {...footerColumnProps} key={section.header} />;
          })}
        </footer>
      )}

      <footer css={globalFooterStyle} {...injectTestId('footer.globalFooter')}>
        <div css={leftContainerStyle}>
          <Image {...injectTestId('footer-logo')} alt="Cvent" src={cventLogo} layout="fixed" width={65} height={19} />
          <div css={cventTextStyle}>{translate('footer.copyright', { year })}</div>
        </div>
        <div css={rightContainerStyle}>
          <TextLink
            href="https://www.cvent.com/en/cvent-general-terms-of-use"
            neutral
            target="_blank"
            rel="noopener noreferrer"
            testID="footer.links.termsOfUse"
            css={linkTextStyle}
          >
            {termsOfUseText}
          </TextLink>
          <span css={divider}>&nbsp; | &nbsp;</span>
          <TextLink
            href="https://www.cvent.com/en/cvent-global-privacy-policy#cookies"
            neutral
            target="_blank"
            rel="noopener noreferrer"
            testID="footer.links.privacyPolicy"
            css={linkTextStyle}
          >
            {cventPrivacyPolicyText}
          </TextLink>
          <span css={divider}>&nbsp; | &nbsp;</span>
          <TextLink
            href="https://www.cvent.com/en/contact/support"
            neutral
            target="_blank"
            rel="noopener noreferrer"
            testID="footer.links.helpAndSupport"
            css={linkTextStyle}
          >
            {helpLinkText}
          </TextLink>
        </div>
      </footer>
    </>
  );
}

export default Footer;
