/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';

import { injectTestId } from '@cvent/nucleus-test-automation';
import Button from '@cvent/carina/components/Button';

import { useTranslate } from 'nucleus-text';
import { useTheme } from '@cvent/carina/components/ThemeProvider';

import { Space } from './Space';

export type GdprCookieProps = {
  alreadyAccepted: boolean;
  onAccept: () => void;
};

/**
 * @param alreadyAccepted boolean to signal if the cookie is accepted or not
 * @param onAccept function to be called when the user accepts the cookie
 */
export function GdprCookieConsent({ alreadyAccepted = false, onAccept }: GdprCookieProps): JSX.Element {
  const { backgroundColor, font, borderColor } = useTheme();
  const [show, setShow] = useState(!alreadyAccepted);
  const { translate, resolveInnerHtmlTranslation } = useTranslate();

  if (!show) {
    return null;
  }

  const Banner = css`
    z-index: 100;
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1.5rem;
    justify-content: center;
    align-items: center;
    border: 1px solid ${borderColor.active};
    background: ${backgroundColor.active};
    span {
      color: ${font.base};
      a {
        color: ${font.color.interactive.base};
        text-decoration: underline;
      }
    }
  `;

  // Using resolveInnerHtmlTranslation to inject the link from the translation as sanitized html instead of plain text.
  const sanitizedBodyText = resolveInnerHtmlTranslation('gdpr.body');

  return (
    <div {...injectTestId('gdpr.banner.root')} css={Banner}>
      <span {...injectTestId('gdpr.banner.body')} dangerouslySetInnerHTML={sanitizedBodyText} />
      <Space />
      <Button
        appearance="lined"
        onClick={() => {
          onAccept();
          setShow(false);
        }}
        text={translate('buttons.accept')}
        testID="gdpr.banner.accept"
      />
    </div>
  );
}

GdprCookieConsent.propTypes = {
  alreadyAccepted: PropTypes.bool,
  onAccept: PropTypes.func
};

export default GdprCookieConsent;
