import React, { useRef } from 'react';
import { css } from '@emotion/react';

import { Modal } from '@cvent/carina/components/Modal';
import Button from '@cvent/carina/components/Button';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { useTranslate } from 'nucleus-text';

interface ConfirmationModalProps {
  /** Function to dismiss the modal upon clicking cancel button on the modal */
  onDismiss: () => void;

  /** Function to trigger when the cancellation is confirmed */
  onConfirm: () => Promise<boolean> | void;

  /** The title text of the modal */
  title: string;

  /** Message to be displayed on the modal. */
  message: string;

  /** Text to be displayed on the button to confirm cancellation */
  affirmativeText: string;

  /** Text to be displayed on the dismiss button */
  negativeText?: string;
}

function ConfirmationModal({
  onDismiss,
  onConfirm,
  title,
  message,
  affirmativeText,
  negativeText
}: ConfirmationModalProps): JSX.Element {
  const htmlElementRef = useRef(document.getElementsByTagName('html')[0]); // for scrollLock
  const { translate } = useTranslate();

  const Container = css`
    padding: 1rem;
    padding-top: 0;
  `;

  const Actions = css`
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 1rem;
    }
  `;

  return (
    <Modal testID="confirmation-modal.dialogue" isOpen portal scrollLock={htmlElementRef} onDismiss={onDismiss}>
      <div css={Container}>
        <h2 {...injectTestId('confirmation-modal.title')}>{title}</h2>
        <p {...injectTestId('confirmation-modal.message')}>{message}</p>
        <div css={Actions}>
          <Button
            appearance="tertiary"
            text={negativeText || translate('buttons.cancel')}
            onClick={onDismiss}
            testID="confirmation-modal.cancel"
          />
          <Button appearance="filled" text={affirmativeText} onClick={onConfirm} testID="confirmation-modal.confirm" />
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
