import { useQuery } from '@apollo/client';
import { IsLoggedInDocument } from '@cvent/developer-portal-graphql/operations';
import { useMemo } from 'react';

/**
 * Custom hook to determine if the current user is logged into the developer portal
 *
 * @returns true if user is logged in, false otherwise
 */
export default function useLoggedIn(): boolean {
  const { data: isLoggedInData } = useQuery(IsLoggedInDocument);

  return useMemo(() => isLoggedInData?.isLoggedIn, [isLoggedInData]);
}
