import React from 'react';
import { useCookies } from 'react-cookie';
import { useCarinaContext } from '@cvent/carina/components/CarinaProvider';
import { Switch } from '@cvent/carina/components/Switch';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { useTranslate } from 'nucleus-text';
import { SELECTED_THEME_COOKIE_NAME } from '../utils/constants';

/**
 * A component used to switch the active theme from light to dark,
 * and vice versa. This also sets a cookie value so the selected theme will
 * persist reloading the site.
 */
export function ThemeSwitcher(): JSX.Element {
  const { setColorScheme } = useCarinaContext();
  const [cookies, setCookie] = useCookies([SELECTED_THEME_COOKIE_NAME]);
  const { translate } = useTranslate();

  /**
   * Handler function for when the selected theme is changed by toggling the theme
   * switcher.
   *
   * @param lightTheme true if the selected theme is light, false if the
   * selected theme is dark
   */
  const handleThemeUpdate = (lightTheme: boolean) => {
    const newTheme = lightTheme ? 'light' : 'dark';
    // set the cookie value for persistence
    setCookie(SELECTED_THEME_COOKIE_NAME, newTheme);
    // set the current colour scheme to be the right value to live-update the theme
    setColorScheme(newTheme);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }} {...injectTestId('header.dark-theme-switcher')}>
      <span style={{ padding: '0 8px' }} {...injectTestId('header.dark-theme-switcher.dark-text')}>
        {translate('themeToggle.dark')}
      </span>
      <Switch
        value={cookies[SELECTED_THEME_COOKIE_NAME] === 'light'}
        onUpdate={handleThemeUpdate}
        {...injectTestId('header.dark-theme-switcher.button')}
      />
      <span style={{ padding: '0 8px' }} {...injectTestId('header.dark-theme-switcher.light-text')}>
        {translate('themeToggle.light')}
      </span>
    </div>
  );
}
