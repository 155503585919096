import { makeVar } from '@apollo/client';
import { getItem } from '@cvent/nextjs';
import { Invitation } from '@cvent/developer-portal-graphql/types';
import { defaultLocale } from '../../locales';

export const darkModeVar = makeVar<boolean>(false);
export const clientVersion = makeVar<string>(String(process.env.CLIENT_VERSION) || 'unknown');
export const localeVar = makeVar<string>(getItem('locale') || defaultLocale);
export const hasInitDatadog = makeVar<boolean>(false);
export const activeInvitationVar = makeVar<Invitation>({});
export const applicationFormDirty = makeVar<boolean>(false);
export const activeAccountMappingId = makeVar<string>(undefined);
