import React, { MouseEventHandler, useMemo } from 'react';
import { Button } from '@cvent/carina/components/Button';
import { MenuIcon } from '@cvent/carina/components/Icon';
import {
  Divider,
  GlobalNavigationLink,
  Logo,
  PageTitle,
  ProductTitle,
  TitleBar,
  TopNavigation as TopNav,
  useNavigation
} from '@cvent/carina/components/Navigation';
import { Menu } from '@cvent/carina/components/Menu';
import { OnWhen } from '@cvent/carina/components/OnWhen';
import { Row } from '@cvent/carina/components/Row';
import { Col } from '@cvent/carina/components/Col';
import NextLink from 'next/link';
import { useTranslate } from 'nucleus-text';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { ThemeSwitcher } from '@components/ThemeSwitcher';
import { UrlObject } from 'url';
import useLoggedIn from '@hooks/loggedIn/useLoggedIn';
import { SELECTED_THEME_COOKIE_NAME } from '../../utils/constants';
import { LoginButton } from './LoginButton';
import { LoggedInOptions } from './LoggedInOptions';

/**
 * A set of the header keys we want translated and included in the header's navigation tabs
 */
const VISIBLE_LOGGED_OUT_HEADER_TRANSLATION_KEYS: Set<string> = new Set<string>([
  'header.overview',
  'header.apireference',
  'header.docSite'
]);

/**
 * A set of the header keys we want translated and included in the header's navigation tabs
 */
const VISIBLE_LOGGED_IN_HEADER_TRANSLATION_KEYS: Set<string> = new Set<string>([
  'header.applications',
  'header.apireference',
  'header.docSite'
]);

// https://github.com/vercel/next.js/issues/5533
const buildLogoLink = ({ item, children }): JSX.Element => (
  <NextLink href={item.url ?? '/'} passHref legacyBehavior>
    <a href="dummy"> {children}</a>
  </NextLink>
);

const DropdownButton = () => {
  const button = (handleClick: MouseEventHandler): JSX.Element => (
    <Button id="trigger-id" testID="collapsable-button" variant="neutral" icon={MenuIcon} onClick={handleClick} />
  );
  return button;
};

/**
 * Header for the Developer Portal
 *
 * @param props[showTitleBar] boolean of whether to show the title bar, defaults to true
 * @returns rendered header for the Developer Portal
 */
export function TopNavigation({ showTitleBar = true }: { showTitleBar?: boolean }): JSX.Element {
  const { logo, page, title, globalNav } = useNavigation();
  const { translate } = useTranslate();
  const router = useRouter();
  const [cookies] = useCookies([SELECTED_THEME_COOKIE_NAME]);
  const isLoggedIn = useLoggedIn();

  const headerTranslationKeys = useMemo(
    () => (isLoggedIn ? VISIBLE_LOGGED_IN_HEADER_TRANSLATION_KEYS : VISIBLE_LOGGED_OUT_HEADER_TRANSLATION_KEYS),
    [isLoggedIn]
  );

  // if the dark theme is enabled, this img -> filter css style will apply, which will invert
  // the colours of the Cvent logo, ensuring it remains visible in both the light
  // and dark themes
  const logoColourInversionStyle =
    cookies[SELECTED_THEME_COOKIE_NAME] === 'dark' ? { img: { filter: 'invert(1) hue-rotate(180deg)' } } : {};

  return (
    <>
      <TopNav testID="header.container">
        <div css={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '100%' }}>
          <div
            css={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              maxWidth: '70%',
              ...logoColourInversionStyle
            }}
          >
            <Logo logo={logo} testID="header.logo" Link={buildLogoLink} />
            <OnWhen m l xl>
              <Divider />
              <ProductTitle testID="header.productTitle" title={translate(title)} />
            </OnWhen>
          </div>
          <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <OnWhen l xl>
              <ul
                role="menu"
                css={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                }}
              >
                {globalNav?.map(item => {
                  // only render the nav item if its translation key is in the list of visible keys for this header
                  if (headerTranslationKeys.has(item.title)) {
                    return (
                      <GlobalNavigationLink testID={`header.navigation.link.${item.title}`} key={item.title}>
                        <NextLink href={item.url.href} passHref legacyBehavior>
                          {translate(item.title)}
                        </NextLink>
                      </GlobalNavigationLink>
                    );
                  }
                  return null;
                })}
              </ul>
            </OnWhen>
          </div>
          <OnWhen s m>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'flex-end'
              }}
            >
              <Menu
                testID="header.collapsable.menu"
                options={globalNav
                  .filter(item => headerTranslationKeys.has(item.title))
                  .map(item => ({ value: translate(item.title), payload: { link: item.url.href } }))}
                onSelect={(item: { payload: { link: UrlObject } }) => router.push(item.payload.link)}
                trigger={DropdownButton()}
              />
            </div>
          </OnWhen>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <ThemeSwitcher />
            {isLoggedIn ? <LoggedInOptions /> : <LoginButton />}
          </div>
        </div>
      </TopNav>
      {showTitleBar && (
        <TitleBar testID="header.titleBar">
          <Row>
            <Col
              width="fill"
              flex={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
              }}
            >
              <PageTitle testID="header.titleBar.pageTitle" title={translate(page)} />
            </Col>
          </Row>
        </TitleBar>
      )}
    </>
  );
}
