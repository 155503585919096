import { Bearer } from '@cvent/ts-client-common';
import Router from 'next/router';
import { ActionType } from '@cvent/carina/components/Templates/utils/helpers';
import { AuthClient } from '@cvent/auth-client';
import { FetchClient } from '@cvent/fetch';
import { LoggerFactory } from '@cvent/nextjs';
import { ENABLED_VARIANT_ID, SELECTED_ACCOUNT_MAPPING_ID, SELECTED_WORKSPACE_ID } from '@utils/constants';
import { Experiment, Variant } from '@cvent/developer-portal-graphql/types';
import { deleteCookie } from 'cookies-next';
import { activeAccountMappingId } from '../config/reactiveVars';

const LOG = LoggerFactory.create('DeveloperPortalUtils');

const authClient = new AuthClient({
  endpoint: process.env.AUTH_SERVICE,
  apiKey: process.env.API_KEY
});
const fetchClient = new FetchClient({ init: { credentials: 'include' } });

/**
 * Query the auth service to verify if a bearer token is valid.
 * If the call causes an exception, or if the call results in a non success call, bearer token is considered invalid
 *
 * @param bearerToken The bearer token we are checking validity of.
 * @returns true if bearer token is valid, false otherwise
 */
export const isBearerTokenValid = async (bearerToken: string): Promise<boolean> => {
  try {
    // verify the access token by calling auth-service verify endpoint. If it returns an error, we
    // assume the bearer token isn't valid
    const verifyResponse = await authClient.verifyAccessToken({
      accessToken: bearerToken,
      refreshAccessToken: true
    });
    if (verifyResponse && verifyResponse.accessToken) {
      return true;
    }
  } catch (error: unknown) {
    return false;
  }
  return false;
};

interface ExperimentVariant {
  weight?: number;
  include?: string[];
}

export const findExperimentVariantEnabled = (experiment: Experiment): Variant => {
  return experiment?.variants?.find(variant => variant.id === ENABLED_VARIANT_ID);
};

/**
 * Determines if the experiment controlling the visibility of the docs site is enabled or not.
 *
 * @param enabledVariant The 'enabled' variant of the docs experiment, variant should have weight and include properties
 * @param isLoggedIn boolean to determine if current user is logged in or not
 * @param accountMappingId current account mapping id the for the user if the user is logged in
 * @returns true if the docs experiment is enabled, false otherwise
 */
export const isDocsExperimentEnabled = (
  enabledVariant: ExperimentVariant,
  isLoggedIn: boolean,
  accountMappingId?: string
): boolean => {
  let isEnabled: boolean = enabledVariant.weight === 100;
  if (!isEnabled && isLoggedIn && accountMappingId) {
    isEnabled = enabledVariant.include?.includes(accountMappingId) ?? false;
  }
  return isEnabled;
};

/**
 * Determines if the experiment controlling the visibility of the termination protection toggle is enabled or not.
 *
 * @param enabledVariant The 'enabled' variant of the termination protection experiment, variant should have weight and include properties
 * @param isLoggedIn boolean to determine if current user is logged in or not
 * @param accountMappingId The user's current account mapping ID (if logged in).
 * @returns true if the termination protection experiment is enabled, false otherwise
 */
export const isTerminationProtectionExperimentEnabled = (
  enabledVariant: ExperimentVariant,
  isLoggedIn: boolean,
  accountMappingId?: string
): boolean => {
  let isEnabled: boolean = enabledVariant.weight === 100;
  if (!isEnabled && isLoggedIn && accountMappingId) {
    isEnabled = enabledVariant.include?.includes(accountMappingId);
  }
  return isEnabled;
};

/**
 * Creates a bearer token header for API calls using the cvent-auth cookie as the value for the header.
 *
 * ex: if cvent-auth=cookie;foo=bar; is found in the string of cookies, this would return
 * { prefix: 'bearer', value: 'cookie' }
 *
 * If no cvent-auth cookie is found, then the result will be undefined
 *
 * @param cookiesString The cookies found for this application in a ';' delimited string
 * @returns A bearer token authorization header built using the value found in the cvent-auth cookie, if found.
 */
export const createBearerTokenHeaderFromCventAuthCookie = (cookiesString?: string): Bearer => {
  if (!cookiesString) {
    return null;
  }
  const cookieList = cookiesString.split(';');
  for (const cookie of cookieList) {
    const [cookieName, cookieValue] = cookie.trim().split('=');
    if (cookieName === process.env.AUTH_COOKIE_NAME) {
      // we found a cvent-auth cookie, use that as our bearer token
      return { prefix: 'bearer', value: cookieValue };
    }
  }
  return null;
};

export const errorGoBackAction = (
  returnUrl: React.MutableRefObject<string>,
  translate: (key: string) => string
): ActionType[] => [
  {
    value: translate('errors.goBack'),
    onClick: (): void => {
      if (returnUrl.current) {
        Router.push(returnUrl.current);
      } else {
        Router.back();
      }
    }
  }
];

/**
 * A simple sort function to sort two strings in ascending order. Used as an input to
 * Array.prototype.sort when sorting scopes in alphabetical order
 *
 * @param firstString   The first string to sort
 * @param secondString  The second string to sort
 * @returns 1 if the first string would come after the second string in alphabetical order,
 * -1 if the second string would come after thr first string in alphabetical order, 0 if the
 * two strings are equal.
 */
export const basicSort = (firstString: string, secondString: string): number => {
  if (firstString > secondString) {
    return 1;
  }
  if (secondString > firstString) {
    return -1;
  }
  return 0;
};

/**
 * A sort function to be used when sorting scopes, that ensures that event scopes come
 * first. This is passed into Array.prototype.sort when sorting the list of scopes.
 * After sorting event scopes first, will sort by scope category, then scope value.
 *
 * @param firstScope first scope name string e.g. "event/admission-items:read"
 * @param secondScope second scope name string e.g. "event/admission-items:read"
 * @returns -1 if the first scope category is equal to 'event', and second scope category is not
 *          OR if the two categories are not event, and the first category is first alphabetically,
 *           1 if the second scope category is equal to 'event' and first scope category is not.
 *          OR if the two categories are not event, and the first category is last alphabetically
 *          Otherwise, returns the results of basicSort() with first and second scope values passed in as first and second arguments respectively
 */
export const scopeSort = (firstScope: string, secondScope: string): number => {
  const [firstScopeCategory, firstScopeValue] = firstScope.split('/');
  const [secondScopeCategory, secondScopeValue] = secondScope.split('/');

  // if it is an event scope, it goes first
  if (firstScopeCategory === 'event' && secondScopeCategory !== 'event') {
    return -1;
  }

  if (secondScopeCategory === 'event' && firstScopeCategory !== 'event') {
    return 1;
  }

  // if it is not event, sort the category first
  if (firstScopeCategory < secondScopeCategory) {
    return -1;
  }

  if (firstScopeCategory > secondScopeCategory) {
    return 1;
  }

  // finally, sort the value.
  return basicSort(firstScopeValue, secondScopeValue);
};

export const deleteOktaSession = async (deleteSessionUrl: string) => {
  try {
    await fetchClient.delete(deleteSessionUrl);
  } catch (error) {
    LOG.debug(error);
  }
};

/**
 * Deletes the account mapping id and workspace id cookies and sets the
 * active account mapping id reactive var to undefined
 */
export const deleteAccountCookies = async () => {
  await activeAccountMappingId(undefined);
  await deleteCookie(SELECTED_ACCOUNT_MAPPING_ID);
  await deleteCookie(SELECTED_WORKSPACE_ID);
};
