import { CSSObject } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useTheme } from '@cvent/carina/components/ThemeProvider';
import { useBreakpoints } from '@cvent/carina/components/Breakpoints';

import { useCustomBreakpoints } from '@hooks/display/useCustomBreakpoints';

export const useFooterStyle = (): Record<string, CSSObject> => {
  const [isMobile, setIsMobile] = useState(false);
  const { backgroundColor, font } = useTheme();
  const breakpoints = useBreakpoints();
  const customBreakpoints = useCustomBreakpoints(breakpoints);

  useEffect(() => {
    setIsMobile(customBreakpoints.isMobile);
  }, [customBreakpoints]);

  return {
    linkTextStyle: {
      color: 'inherit',
      textDecoration: 'none'
    },
    globalFooterStyle: {
      width: 'auto',
      backgroundColor: backgroundColor?.surface,
      fontSize: font.base.size.xxs,
      fontWeight: font.base.weight.regular,
      color: font.color.soft,
      display: 'flex',
      justifyContent: isMobile ? 'center' : 'space-between',
      padding: '0.6rem 1rem 0.6rem 1rem',
      flexDirection: isMobile ? 'column' : 'row'
    },
    leftContainerStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: isMobile ? 'column' : 'row'
    },
    rightContainerStyle: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: isMobile ? 'center' : 'flex-end'
    },
    cventTextStyle: { whiteSpace: 'nowrap', margin: isMobile ? '0rem 0rem 0.3rem 0.5rem' : '0rem 0rem 0rem 1rem' },
    divider: {
      color: 'inherit'
    },
    column: {
      marginInlineEnd: isMobile ? 'none' : 'auto',
      justifyContent: isMobile ? 'center' : 'space-between'
    },
    columnHeader: {
      fontSize: font.base.size.s,
      fontWeight: font.base.weight.bold,
      color: font.color.soft,
      margin: '0rem 0rem 0.5rem 0rem'
    },
    columnLinkList: {
      fontSize: font.base.size.xs,
      fontWeight: font.base.weight.regular,
      color: font.color.soft,
      textDecoration: 'none'
    },
    publicFooterStyle: {
      backgroundColor: backgroundColor?.base,
      fontSize: font.base.size.xxs,
      fontWeight: font.base.weight.regular,
      color: font.color.soft,
      display: 'flex',
      justifyContent: isMobile ? 'center' : 'space-between',
      padding: '1.5rem 1.5rem 1.5rem 1.5rem',
      flexDirection: isMobile ? 'column' : 'row',
      textAlign: isMobile ? 'center' : 'left',
      placeItems: isMobile ? 'center' : 'left'
    },
    linkListStyle: {
      svg: {
        fill: 'rgb(105,113,122) !important'
      },
      '& > li': isMobile
        ? {
            textAlign: 'center',
            width: 'unset'
          }
        : {}
    }
  };
};

export default useFooterStyle;
