import { BreakpointsContextType } from '@cvent/carina/components/Breakpoints';

/**
 * A type that describes the shape of the object returned by the useCustomBreakpoints hook.
 */
type CustomBreakpoints = {
  /** Whether the current breakpoint is default size or S. */
  isMobile: boolean;
  /** Whether the current breakpoint is M, L, or XL. */
  isDesktop: boolean;
  /** Whether the current breakpoint is default size, S, or M. */
  isMobileOrTablet: boolean;
};

/**
 * A custom hook that uses the provided breakpoints context to determine if the current
 * breakpoint is mobile, desktop, or mobile or tablet.
 *
 * @param breakpoints - A BreakpointsContextType object containing information about the current breakpoint.
 * @returns Details on where to create breakpoints based on the provided context type
 */
export const useCustomBreakpoints = (breakpoints: BreakpointsContextType): CustomBreakpoints => ({
  isMobile: breakpoints.isDefaultSize || breakpoints.isS,
  isDesktop: breakpoints.isM || breakpoints.isL || breakpoints.isXL,
  isMobileOrTablet: breakpoints.isDefaultSize || breakpoints.isS || breakpoints.isM
});
