import React, { ReactNode } from 'react';
import Head from 'next/head';
import { NavigationProvider, NavigationProviderProps } from '@cvent/carina/components/Navigation';
import { useTranslate } from 'nucleus-text';

function NavigationPage({
  nav,
  pageTitle,
  children
}: {
  nav: NavigationProviderProps;
  pageTitle: string;
  children: ReactNode;
}): JSX.Element {
  const { translate } = useTranslate();
  return (
    <>
      <Head>
        <title>{translate(pageTitle)}</title>
      </Head>
      <NavigationProvider {...nav}>{children}</NavigationProvider>
    </>
  );
}

export default NavigationPage;
