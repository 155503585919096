import React, { ReactNode } from 'react';
import { CarinaProvider } from '@cvent/carina/components/CarinaProvider';
import { getDefaultTheme } from '@cvent/carina/utils/tokens';
import { useCookies } from 'react-cookie';
import { SELECTED_THEME_COOKIE_NAME } from '../../utils/constants';

/**
 * A provider to wrap the CarinaProvider. Doing this allows us to wrap this provider with
 * the CookiesProvider in AppProviders, meaning we can use cookies here. This enables us to use a cookie value
 * for the current colour scheme inside of the CarinaProvider.
 */
export default function ThemeProvider({ children }: { children: ReactNode }): JSX.Element {
  const [cookies, setCookies] = useCookies();

  if (!cookies[SELECTED_THEME_COOKIE_NAME]) {
    setCookies(SELECTED_THEME_COOKIE_NAME, 'light');
  }

  return (
    <CarinaProvider
      themes={[getDefaultTheme()]}
      breakpoints={{ ssrSize: 'm' }}
      key={typeof window === 'undefined' ? 'ssr' : 'csr'}
      colorScheme={cookies[SELECTED_THEME_COOKIE_NAME] || 'light'}
    >
      {children}
    </CarinaProvider>
  );
}
