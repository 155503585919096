import React, { useCallback, useEffect, useState } from 'react';
import { Menu } from '@cvent/carina/components/Menu';
import {
  GetDevelopersDocument,
  GetInvitationsDocument,
  GetInvitationsQuery
} from '@cvent/developer-portal-graphql/operations';
import {
  AppSwitcherIcon,
  LogOutIcon,
  MailIcon,
  PersonAccountIcon,
  UserIcon,
  UsersIcon
} from '@cvent/carina/components/Icon';
import { Button } from '@cvent/carina/components/Button';
import { css } from '@emotion/react';
import { Badge } from '@cvent/carina/components/Badge';
import { Space } from '@components/Space';
import { useTranslate } from 'nucleus-text';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { SELECTED_ACCOUNT_MAPPING_ID, SELECTED_THEME_COOKIE_NAME } from '@utils/constants';
import ConfirmationModal from '@components/dialogues/ConfirmationModal';
import { deleteAccountCookies } from '@utils';
import { activeAccountMappingId, applicationFormDirty } from '../../config/reactiveVars';

// overriding the default size of the icon to pass into the menu, cannot do this inline
// so we do it here
const accountSwitcherButtonIcon = (): JSX.Element => <AppSwitcherIcon size="l" />;

/**
 * Build the button for the account switcher
 *
 * @param currentAccountName name of the current account user has selected
 * @returns rendered button for the account switcher
 */
const buildAccountSwitcherButton = currentAccountName => {
  const button = (handleClick): JSX.Element => (
    <Button
      id="trigger-id"
      testID="header.account-switcher.menu.button"
      variant="neutral"
      appearance="ghost"
      icon={accountSwitcherButtonIcon}
      text={currentAccountName}
      onClick={handleClick}
      css={{ paddingLeft: 0 }}
    />
  );
  return button;
};

/**
 *
 * @param email email of logged in user
 * @param translate translate method that will translate the keys into regional displays
 * @param invitationsData current invitations data for user
 * @returns Rendered options for user actions menu
 */
const buildDeveloperOptions = (
  email: string,
  translate: (str: string) => string,
  invitationsData: GetInvitationsQuery
): Array<object> => {
  const devOptions = [
    {
      value: email,
      label: (): JSX.Element => (
        <>
          <UserIcon />
          <Space />
          {email}
        </>
      ),
      disabled: true
    },
    {
      value: translate('buttons.invitations'),
      label: (): JSX.Element => (
        <>
          <MailIcon />
          <Space />
          {translate('buttons.invitations')}
          <Space />
          {/* render a badge with the number of pending invitations, if any are found */}
          {invitationsData?.getInvitations && invitationsData.getInvitations.length > 0 && (
            <Badge
              content={invitationsData.getInvitations.length.toString()}
              {...injectTestId('header.user-actions.pending-invitations.count.badge')}
            />
          )}
        </>
      )
    },
    {
      value: translate('buttons.logout'),
      label: (): JSX.Element => (
        <>
          <LogOutIcon />
          <Space />
          {translate('buttons.logout')}
        </>
      )
    }
  ];
  return devOptions;
};

const PersonIconContainer = css`
  display: flex;
  span[data-cvent-id='header.user-actions.pending-invitations.notification.badge'] {
    margin-left: -10px;
    top: 10px;
    position: relative;
  }
`;

/**
 * A menu button component for user actions, that includes both the clickable icon and an optional
 * notification badge if the logged-in user has pending invitations
 */
const buildUserActionMenuButton = invitationData => {
  const button = (handleClick): JSX.Element => (
    <div css={PersonIconContainer}>
      <Button
        testID="header.user-actions.menu.button"
        appearance="ghost"
        variant="neutral"
        icon={PersonAccountIcon}
        onClick={handleClick}
      />
      {invitationData && invitationData.length > 0 && (
        <Badge {...injectTestId('header.user-actions.pending-invitations.notification.badge')} />
      )}
    </div>
  );
  return button;
};

/**
 * Builds the label for the workspace switcher menu
 *
 * @param name Name of the current workspace
 * @returns
 */
const buildWorkspaceSwitcherMenuLabel = (name: string): JSX.Element => (
  <>
    <UsersIcon />
    <Space />
    {name}
  </>
);

/**
 * Component that contains the options for a logged in user in the top navigation header
 *
 * @returns Rendered options for logged in user
 */
export function LoggedInOptions(): React.JSX.Element {
  const _applicationFormDirty: boolean = useReactiveVar(applicationFormDirty);
  const _activeAccountMappingId: string = useReactiveVar(activeAccountMappingId);
  const { translate } = useTranslate();
  const { data: accountsData, loading: accountsLoading } = useQuery(GetDevelopersDocument, {
    variables: {
      include: 'accounts.name'
    }
  });

  const {
    loading: invitationsLoading,
    data: invitationsData,
    refetch: refetchInvitations
  } = useQuery(GetInvitationsDocument, {
    fetchPolicy: 'no-cache'
  });

  const [cookies, setCookie] = useCookies([SELECTED_ACCOUNT_MAPPING_ID, SELECTED_THEME_COOKIE_NAME]);

  // set the accountMappingId cookie when the activeAccountMappingId reactive var changes
  useEffect(() => {
    if (_activeAccountMappingId) {
      setCookie(SELECTED_ACCOUNT_MAPPING_ID, _activeAccountMappingId);
    }
  }, [_activeAccountMappingId, cookies, setCookie]);

  // set the activeAccountMappingId reactive var on page load if the cookie is set. This is the case
  // where a user is returning and was previously logged in, therefore we need to prime the reactive
  // var with the value from the cookie
  useEffect(() => {
    if (!_activeAccountMappingId) {
      activeAccountMappingId(cookies[SELECTED_ACCOUNT_MAPPING_ID]);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const router = useRouter();

  const [showCancelModal, setShowCancelModal] = useState(false);

  const setAccountAndRedirect = useCallback(() => {
    applicationFormDirty(false);
    // path has to be passed in the options param on setCookie or else multiple accountMappingId cookies could be set
    // for different pages, which breaks everything. see https://www.npmjs.com/package/react-cookie
    if (_activeAccountMappingId && _activeAccountMappingId !== cookies[SELECTED_ACCOUNT_MAPPING_ID]) {
      setCookie(SELECTED_ACCOUNT_MAPPING_ID, _activeAccountMappingId, { path: '/' });
    }

    setShowCancelModal(false);
    refetchInvitations();

    router.push('/applications');
  }, [_activeAccountMappingId, router, cookies, setCookie, refetchInvitations]);

  useEffect(() => {
    if (_activeAccountMappingId !== undefined && _activeAccountMappingId !== cookies[SELECTED_ACCOUNT_MAPPING_ID]) {
      if (_applicationFormDirty) {
        setShowCancelModal(true);
      } else {
        setAccountAndRedirect();
      }
    }
  }, [_applicationFormDirty, cookies, setAccountAndRedirect, _activeAccountMappingId]);

  const currentUser = accountsData?.getDevelopers[0];
  const accounts = accountsData?.getDevelopers[0].accounts;

  // when rendering, if no account is set in the cookies, use the first one in the list of accounts once the accounts are loaded
  if (!cookies[SELECTED_ACCOUNT_MAPPING_ID] && accounts?.length) {
    setCookie(SELECTED_ACCOUNT_MAPPING_ID, accounts[0].id);
  }

  if (accountsLoading || invitationsLoading) {
    return null;
  }

  return (
    <>
      <Menu
        aria-labelledby="trigger-id"
        testID="header.user-actions.menu"
        options={buildDeveloperOptions(currentUser?.email, translate, invitationsData)}
        onSelect={(option): void => {
          if (option.value === translate('buttons.invitations')) {
            router.push('/invitations');
          }
          if (option.value === translate('buttons.logout')) {
            // we need to clear the user's cookies before logging them out in order to ensure a new user
            // has a clean slate when they log in. This was causing a bug where the user would get an error
            // trying to load applications belonging to the previously logged in user.
            deleteAccountCookies();
            router.push('/logout');
          }
        }}
        portal
        trigger={buildUserActionMenuButton(invitationsData?.getInvitations)}
      />
      <Menu
        aria-labelledby="trigger-id"
        maxHeight="200px"
        testID="header.account-switcher.menu"
        // disabling as the menu component doesn't clearly export any of its types
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        options={accounts?.map(account => ({
          value: account.id,
          label: buildWorkspaceSwitcherMenuLabel(account.name)
        }))}
        onSelect={(option): void => {
          activeAccountMappingId(option.value);
        }}
        portal
        trigger={buildAccountSwitcherButton(
          accounts?.find(account => account.id === cookies[SELECTED_ACCOUNT_MAPPING_ID])?.name
        )}
        {...(cookies[SELECTED_ACCOUNT_MAPPING_ID] ? { selected: cookies[SELECTED_ACCOUNT_MAPPING_ID] } : {})}
      />
      <Space />
      {showCancelModal && (
        <ConfirmationModal
          onDismiss={(): void => {
            activeAccountMappingId(cookies[SELECTED_ACCOUNT_MAPPING_ID]);
            setShowCancelModal(false);
          }}
          onConfirm={(): void => {
            setAccountAndRedirect();
          }}
          title={translate('switchingAccounts')}
          message={translate('changesWillBeLost')}
          affirmativeText={translate('discardChanges')}
        />
      )}
    </>
  );
}
